import * as React from 'react';
import { trpc } from '../utils/trpc-client';
import { Badge, Cell, Header, Table } from '../components/table';
import { formatNumber } from '../utils/formatting-utils';
import { formatDate } from 'shared/src/date-utils';
import { PublishedModelWithLicenseDetails, TagDetail } from 'shared/src/metadata-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashCan } from '@fortawesome/free-solid-svg-icons';
import { RefetchOptions } from '@tanstack/react-query';
import { EditMetadataModal } from '../components/edit-metadata-modal';
import ConfirmDialog from '../components/confirm-dialog';

export function PublishedSection() {
  const { data, isLoading, refetch } = trpc.modelsLicense.useQuery();
  const [sortColumn, setColumn] = React.useState('modelId');
  if (isLoading) {
    return <div>Loading...</div>;
  }

  function onSort(e: React.MouseEvent<HTMLTableCellElement, MouseEvent>, column: string) {
    setColumn(column);
  }

  return (
    <Table>
      <thead className="bg-gray-50">
        <tr>
          <th
            scope="col"
            className="px-3 py-5 text-left text-sm font-semibold text-gray-900"
            onClick={e => onSort(e, 'modelId')}>
            Model Id
          </th>
          <th
            scope="col"
            className="px-3 py-5 text-left text-sm font-semibold text-gray-900"
            onClick={e => onSort(e, 'numParameters')}>
            Num Params
          </th>
          <th
            scope="col"
            className="px-3 py-5 text-left text-sm font-semibold text-gray-900"
            onClick={e => onSort(e, 'modelType')}>
            Model Type
          </th>
          <th
            scope="col"
            className="px-3 py-5 text-left text-sm font-semibold text-gray-900"
            onClick={e => onSort(e, 'license')}>
            License
          </th>
          <th
            scope="col"
            className="px-3 py-5 text-left text-sm font-semibold text-gray-900"
            onClick={e => onSort(e, 'firstPublished')}>
            Publish Date
          </th>
          <th
            scope="col"
            className="px-3 py-5 text-left text-sm font-semibold text-gray-900"
            onClick={e => onSort(e, 'contextWindowSize')}>
            Context Size
          </th>
          <Header label="Formats" />
          <Header label="Tags" />
          <Header label="" />
        </tr>
      </thead>
      <tbody className="divide-y divide-gray-200 bg-white">
        {
          // @ts-ignore
          [...data]
            .sort((a, b) => (a[sortColumn] > b[sortColumn] ? 1 : -1))
            .map(model => (
              <tr key={model.modelId}>
                <Cell>
                  <ModelModal model={model} refetch={refetch} />
                </Cell>
                <Cell>{formatNumber(model.numParameters)}</Cell>
                <Cell>{model.modelType}</Cell>
                <Cell>{model.license}</Cell>
                <Cell>{formatDate(model.firstPublished)}</Cell>
                <Cell>{model.contextWindowSize}</Cell>
                <Cell>
                  <div className="flex gap-1">
                    {model.quantizedFiles
                      // @ts-ignore
                      .sort((a, b) => a.quantMethod.localeCompare(b.quantMethod))
                      .map((qfile: { quantMethod: string }) => (
                        <Badge
                          key={model.modelId + qfile.quantMethod}
                          label={qfile.quantMethod}
                          color="blue"
                        />
                      ))}
                  </div>
                </Cell>
                <Cell>
                  <div className="flex gap-1">
                    <TagsColumn modelId={model.modelId} tags={model.tags} />
                  </div>
                </Cell>
                <Cell>
                  <DeleteButton model={model} refetch={refetch} />
                </Cell>
              </tr>
            ))
        }
      </tbody>
    </Table>
  );
}

type ModelModalTypes = {
  model: PublishedModelWithLicenseDetails;
  refetch: (options?: RefetchOptions) => unknown;
};

function ModelModal({ model, refetch }: ModelModalTypes) {
  const [open, setOpen] = React.useState(false);
  return (
    <>
      <div
        onClick={() => setOpen(true)}
        className="text-indigo-600 hover:cursor-pointer hover:text-indigo-900">
        {model.modelId}
      </div>
      <EditMetadataModal model={model} open={open} setOpen={setOpen} refetch={refetch} />
    </>
  );
}

type DeleteButtonProps = {
  model: PublishedModelWithLicenseDetails;
  refetch: (options?: RefetchOptions) => unknown;
};

function DeleteButton({ model, refetch }: DeleteButtonProps) {
  const [open, setOpen] = React.useState(false);
  const { mutateAsync } = trpc.unpublishModel.useMutation();

  async function onConfirm() {
    await mutateAsync({ id: model.modelId });
    refetch();
    setOpen(false);
  }

  return (
    <>
      <FontAwesomeIcon
        icon={faTrashCan}
        className="text-gray-400 hover:cursor-pointer hover:text-red-600"
        onClick={() => setOpen(true)}
      />
      <ConfirmDialog
        title="Unpublish Model"
        open={open}
        setOpen={setOpen}
        onConfirm={onConfirm}
        message={
          <p className="text-sm text-gray-500">
            Are you sure you want to unpublish the{' '}
            <span className="font-bold">{model.modelId}</span> model? Any consumers of the Kurator
            API will no longer be able to download this model
          </p>
        }
      />
    </>
  );
}

type TagsColumnProps = {
  modelId: string;
  tags: TagDetail[];
};

function TagsColumn({ modelId, tags }: TagsColumnProps) {
  return (
    tags.length > 0 && (
      <>
        <Badge key={modelId + tags[0].id} label={tags[0].name} color="green" />
        {tags.length > 1 && (
          <Badge key={`${modelId}-plus`} label={`+${tags.length - 1}`} color="green" />
        )}
      </>
    )
  );
}
