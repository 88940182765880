import {
  ConvertedFile,
  EditableModelFields,
  PublishedModelWithLicenseDetails,
  QuantizedFile
} from 'shared/src/metadata-types';
import Modal from './modal';
import React, { useState } from 'react';
import { formatBytes } from '../utils/formatting-utils';
import { formatDate } from 'shared/src/date-utils';
import { SecondaryButton } from './forms';
import { EditableModelProperty, ModelProperty, TableHeader } from '../pages/edit-metadata-page';
import { trpc } from '../utils/trpc-client';
import { RefetchOptions } from '@tanstack/react-query';
import ModelGroupSelector from './model-group-selector';
import ModelTagSelector from './model-tag-selector';

type Props = {
  model: PublishedModelWithLicenseDetails;
  open: boolean;
  setOpen: (open: boolean) => void;
  refetch: (options?: RefetchOptions) => unknown;
};

export function EditMetadataModal({ model, open, setOpen, refetch }: Props) {
  const [overrides, setOverrides] = useState<EditableModelFields>({});
  const { mutateAsync } = trpc.editModel.useMutation();

  async function setOverride(key: keyof EditableModelFields, value: any) {
    setOverrides(prev => ({ ...prev, [key]: value }));
  }

  async function updateMetadata() {
    await mutateAsync({ modelId: model.modelId, fields: overrides });
    setOpen(false);
    setOverrides({});
    refetch();
  }

  return (
    <Modal
      title={model.modelId}
      open={open}
      setOpen={setOpen}
      content={<Metadata model={model} overrides={overrides} setOverride={setOverride} />}
      footer={<Footer enabled={Object.keys(overrides).length > 0} submit={updateMetadata} />}
    />
  );
}

type MetadataProps = {
  model: PublishedModelWithLicenseDetails;
  overrides: EditableModelFields;
  setOverride: (key: keyof EditableModelFields, value: any) => void;
};

function Metadata({ model, overrides, setOverride }: MetadataProps) {
  return (
    <div className="px-8 pb-4">
      <EditableModelProperty
        label="Name"
        value={model.name}
        setOverride={value => setOverride('name', value)}
        override={overrides.name}
      />
      <ModelProperty label="Publisher" value={model.source.name} />
      <EditableModelProperty
        label="Description"
        value={model.description}
        setOverride={value => setOverride('description', value)}
        override={overrides.description}
      />
      <EditableModelProperty
        label="Number of Parameters"
        value={String(model.numParameters)}
        setOverride={value => setOverride('numParameters', value)}
        override={overrides.numParameters}
      />
      <div className="flex gap-x-8">
        <ModelProperty label="Date Published" value={formatDate(model.firstPublished)} />
      </div>
      <div className="flex gap-x-8">
        <EditableModelProperty
          label="Info Url"
          value={model.infoUrl}
          setOverride={value => setOverride('infoUrl', value)}
          override={overrides.infoUrl}
        />
        <EditableModelProperty
          label="Paper Url"
          value={model.infoUrl}
          setOverride={value => setOverride('paperUrl', value)}
          override={overrides.paperUrl}
        />
      </div>
      <div className="flex gap-x-8">
        <ModelProperty label="Model Type" value={model.modelType} />
        <ModelProperty label="Library Name" value={model.libraryName} />
      </div>
      <EditableModelProperty
        label="Base Model"
        value={model.baseModel}
        setOverride={value => setOverride('baseModel', value)}
        override={overrides.baseModel}
      />
      <EditableModelProperty
        label="License"
        value={model.license}
        setOverride={value => setOverride('license', value)}
        override={overrides.license}
      />
      <EditableModelProperty
        label="License Link"
        value={model.licenseDetails?.licenseLink || ''}
        setOverride={value => {
          model.licenseDetails!.licenseLink = value;
          setOverride('licenseDetails', model.licenseDetails);
        }}
        override={overrides.licenseDetails?.licenseLink || ''}
      />
      <EditableModelProperty
        label="License Body"
        value={model.licenseDetails?.body || ''}
        setOverride={value => {
          model.licenseDetails!.body = value;
          setOverride('licenseDetails', model.licenseDetails);
        }}
        override={overrides.licenseDetails?.body || ''}
      />
      <EditableModelProperty
        label="Terms Of Use"
        value={model.licenseDetails?.termsOfUse || ''}
        setOverride={value => {
          model.licenseDetails!.termsOfUse = value;
          setOverride('licenseDetails', model.licenseDetails);
        }}
        override={overrides.licenseDetails?.termsOfUse || ''}
      />
      <EditableModelProperty
        label="Copyright"
        value={model.licenseDetails?.copyright || ''}
        setOverride={value => {
          model.licenseDetails!.copyright = value;
          setOverride('licenseDetails', model.licenseDetails);
        }}
        override={overrides.licenseDetails?.copyright || ''}
      />
      <ModelProperty
        label="Languages"
        value={model.languages.length > 0 ? model.languages.join(',') : 'NA'}
      />
      <EditableModelProperty
        label="Trained For"
        value={model.trainedFor}
        setOverride={value => setOverride('trainedFor', value)}
        override={overrides.trainedFor}
      />
      <ModelProperty label="Context Window Size" value={model.contextWindowSize.toString()} />
      <ModelProperty label="Knowledge Cuttoff" value={model.knowledgeCutOff} />
      <ModelGroupProperty
        label="Model Group"
        groupId={overrides.groupId || model.groups[0].id}
        setGroupId={groupId => setOverride('groupId', groupId)}
      />
      <ModelTagProperty
        label="Model Tags"
        tagIds={overrides.tagIds || model.tags.map(tag => tag.id)}
        setTagIds={tagIds => setOverride('tagIds', tagIds)}
      />
      <Files files={model.quantizedFiles} convertedFiles={model.convertedFiles} />
    </div>
  );
}

interface FooterProps {
  enabled: boolean;
  submit: () => unknown;
}

function Footer({ enabled, submit }: FooterProps) {
  return (
    <div className="flex h-20 items-center justify-end rounded-b-lg border-t-[1px] px-4">
      <SecondaryButton disabled={!enabled} label="Update Metadata" onClick={submit} />
    </div>
  );
}

function Files({
  files,
  convertedFiles
}: {
  files: QuantizedFile[];
  convertedFiles: ConvertedFile[];
}) {
  return (
    <div className="mr-8 mt-8">
      <div className="text-sm italic text-gray-500">Files</div>
      <div className="text-md">
        <div className="mt-2 grid grid-cols-[auto_1fr_1fr_1fr_1fr_1fr_1fr_1fr_1fr] gap-x-16 gap-y-1">
          <TableHeader label="Type" />
          <TableHeader label="Generated On" />
          <TableHeader label="Quant Method" />
          <TableHeader label="Quant Engine" />
          <TableHeader label="Max Ram Usage" />
          <TableHeader label="Download Url" />
          <TableHeader label="Size" />
          <TableHeader label="Benchmarks" />
          <TableHeader label="Evaluations" />
          {files.map(file => (
            <Quantized file={file} key={file.sha256} />
          ))}
          {convertedFiles.map(file => (
            <Converted file={file} key={file.sha256} />
          ))}
        </div>
      </div>
    </div>
  );
}

function Quantized({ file }: { file: QuantizedFile }) {
  return (
    <React.Fragment>
      <div>Quantized</div>
      <div>{formatDate(file.generatedOn)}</div>
      <div>{file.quantMethod}</div>
      <div>{file.quantEngine}</div>
      <div>{formatBytes(file.maxRamUsage)}</div>
      <div>
        <a href={file.downloadUrl} className="text-indigo-600 hover:text-indigo-900">
          {file.downloadUrl}
        </a>
      </div>
      <div>{formatBytes(file.sizeBytes)}</div>
      <div style={{ whiteSpace: 'pre-line' }}>
        {file.benchmarks.map(e => e.name + ':' + e.value + '\n')}
      </div>
      <div style={{ whiteSpace: 'pre-line' }}>
        {file.evaluations.map(e => e.name + ':' + e.value + '\n')}
      </div>
    </React.Fragment>
  );
}

function Converted({ file }: { file: ConvertedFile }) {
  return (
    <React.Fragment>
      <div>Unquantized</div>
      <div>{formatDate(file.generatedOn || '')}</div>
      <div>None</div>
      <div>{file.conversionEngine}</div>
      <div>{formatBytes(file.maxRamUsage || 0)}</div>
      <div>
        <a href={file.downloadUrl || '#'} className="text-indigo-600 hover:text-indigo-900">
          {file.downloadUrl}
        </a>
      </div>
      <div>{formatBytes(file.sizeBytes || 0)}</div>
      <div style={{ whiteSpace: 'pre-line' }}>
        {/*{Converted files have no benchmarks; only quantized ones do*/}
      </div>
      <div style={{ whiteSpace: 'pre-line' }}>
        {file.evaluations?.map(e => e.name + ':' + e.value + '\n')}
      </div>
    </React.Fragment>
  );
}

type ModelGroupPropertyProps = {
  label: string;
  groupId?: number;
  setGroupId: (groupId: number) => void;
};

export function ModelGroupProperty({ label, groupId, setGroupId }: ModelGroupPropertyProps) {
  return (
    <div className="mr-8 mt-6">
      <div className="text-sm italic text-gray-500">{label}</div>
      <ModelGroupSelector groupId={groupId} onGroupChange={groupId => setGroupId(groupId)} />
    </div>
  );
}

type ModelTagPropertyProps = {
  label: string;
  tagIds?: number[];
  setTagIds: (tagIds: number[]) => void;
};

export function ModelTagProperty({ label, tagIds, setTagIds }: ModelTagPropertyProps) {
  return (
    <div className="mr-8 mt-6">
      <div className="text-sm italic text-gray-500">{label}</div>
      <ModelTagSelector tagIds={tagIds} onTagChange={setTagIds} />
    </div>
  );
}
